import React, { FC, useCallback, useState } from 'react';

import NavItem from './NavItem/NavItem';
import { NavProps } from './models';
import './HeaderNavigation.scss';
import './HeaderNavigationExtends.scss';

const HeaderNavigation: FC<NavProps> = ({ allNodeNavigation: { nodes }, handleActiveListPage }) => {
  const [activeMainCategory, setActiveMainCategory] = useState<string | null>(null);

  const handleNavigationItemClick = useCallback(
    (id: string | null) => () => {
      setActiveMainCategory(id === activeMainCategory ? null : id);
    },
    [activeMainCategory]
  );

  return (
    <nav className="main-navigation">
      <ul className="main-navigation__list">
        {nodes.map((node) => (
          <NavItem
            key={node.properties.label}
            {...{
              handleActiveListPage,
              handleNavigationItemClick,
              activeMainCategory,
              navItem: node,
            }}
          />
        ))}
      </ul>
    </nav>
  );
};

export default HeaderNavigation;
