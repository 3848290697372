import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Footer from 'components/Footer';
import { AllSitePageContextProvider } from 'context/allSitePage';
import { SiteSettingsContextProvider } from 'context/siteSettings';
import Header from '../../components/Header/Header';

import 'styles/main.scss';

import { LayoutProps, StaticPropsType } from './models';
import './Layout.scss';

export const CommonSettingsContext = React.createContext({});

const Layout: FC<LayoutProps> = ({
  children,
  seo,
  headerTransparent,
  className,
  defaultCompositions,
  handleActiveListPage,
  disclaimerDesktopImage,
  disclaimerMobileImage,
  hideHeader,
  hideFooter,
}) => {
  const { siteSettings, commonSettings, footer, navigation } = defaultCompositions;
  const { allBrandSettings, brandSettings, allSitePage } = useStaticQuery<StaticPropsType>(
    graphql`
      query {
        allBrandSettings {
          nodes {
            brandName
            brandLogo {
              fallbackUrl
              svg {
                content
              }
            }
            openGraphImage {
              url
            }
            brandLogoAlt
            brandLogoLinkAriaLabel
            brandName
            youtubeUrl
            facebookUrl
            lang
            mediaLinks {
              properties {
                mediaAriaLabel
                altLabel
                image {
                  fallbackUrl
                  svg {
                    content
                  }
                }
                link {
                  url
                  name
                }
              }
            }
            imageDisclaimer {
              properties {
                disclaimerFixed
                disclaimerLabel
                disclaimerDesktopSvg {
                  fallbackUrl
                  svg {
                    content
                  }
                }
                disclaimerMobileSvg {
                  fallbackUrl
                  svg {
                    content
                  }
                }
              }
            }
          }
        }
        brandSettings {
          brandName
          brandLogo {
            fallbackUrl
            svg {
              content
            }
          }
          openGraphImage {
            url
          }
          brandLogoAlt
          brandLogoLinkAriaLabel
          brandName
          youtubeUrl
          facebookUrl
          lang
          mediaLinks {
            properties {
              mediaAriaLabel
              altLabel
              image {
                fallbackUrl
                svg {
                  content
                }
              }
              link {
                url
                name
              }
            }
          }
          imageDisclaimer {
            properties {
              disclaimerFixed
              disclaimerLabel
              disclaimerDesktopSvg {
                fallbackUrl
                svg {
                  content
                }
              }
              disclaimerMobileSvg {
                fallbackUrl
                svg {
                  content
                }
              }
            }
          }
        }
        allSitePage {
          nodes {
            path
            context {
              id
              umbracoId
              url
              name
              lang
            }
          }
        }
      }
    `
  );
  const brandSettingsByLang = allBrandSettings
    ? allBrandSettings.nodes.filter(({ lang }) => lang === siteSettings.lang)[0]
    : brandSettings;

  return (
    <CommonSettingsContext.Provider value={commonSettings}>
      <SiteSettingsContextProvider value={siteSettings}>
        <AllSitePageContextProvider value={allSitePage}>
          <div
            className={classNames('layout layout-wrapper', className, {
              'layout--transparent': headerTransparent,
            })}
          >
            <Seo
              siteSettings={siteSettings}
              brandSettings={brandSettingsByLang}
              {...seo}
              lang={siteSettings.lang}
            />
            <PageSchema
              type="WebPage"
              siteSettings={siteSettings}
              brandSettings={brandSettingsByLang}
              name={seo.seoMetaTitle}
              data={{
                metaTitle: seo.seoMetaTitle,
                metaDescription: seo.seoMetaDescription,
                metaKeywords: seo.seoMetaKeywords,
              }}
            />
            <a
              className="sr-only"
              href="#main"
              aria-label={navigation.skipContent[0].properties.skipContentTextAriaLabel}
            >
              {navigation.skipContent[0].properties.skipContentText}
            </a>
            {hideHeader ? null : (
              <Header
                {...{
                  handleActiveListPage,
                  navigationTexts: navigation,
                  logo: brandSettingsByLang,
                  languages: siteSettings.languages,
                  languageLetters: siteSettings.languageLetters,
                  baseLang: siteSettings.baseLang,
                }}
                searchTexts={{
                  ...commonSettings,
                  closeSearchAreaLabel: commonSettings.closeSeachAreaLabel,
                }}
                allNodeNavigation={{
                  nodes: navigation.navigation,
                }}
              />
            )}

            <main id="main" className="layout__main">
              {children}
            </main>

            {hideFooter ? null : (
              <Footer
                {...{
                  mediaLinks: brandSettingsByLang.mediaLinks,
                  navigation: footer.navigation,
                  bottomLinks: footer.bottomLinks,
                  copyrightBlock: footer.copyrightBlock,
                  hideMediaLinks: footer.hideMediaLinks,
                  disclaimer: footer.disclaimer,
                  disclaimerDesktopImage,
                  disclaimerMobileImage,
                  imageDisclaimer: brandSettingsByLang.imageDisclaimer,
                }}
                dataLogo={brandSettingsByLang}
              />
            )}
          </div>
        </AllSitePageContextProvider>
      </SiteSettingsContextProvider>
    </CommonSettingsContext.Provider>
  );
};

export default Layout;
