import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import classNames from 'classnames';
import { IGatsbyImageComponentProps } from './models';
import 'common/GatsbyImage/GatsbyImage.scss';

const GatsbyImage: FC<IGatsbyImageComponentProps> = ({
  isLazyLoading,
  alt,
  fluid,
  wrapperClasses,
  objectPosition,
  ...restProps
}) => {
  return fluid ? (
    <div
      className={classNames(`gatsby-image-wrapper`, {
        [`${wrapperClasses}`]: wrapperClasses,
      })}
    >
      <Img
        objectPosition={objectPosition || '50% 50%'}
        loading={isLazyLoading ? 'lazy' : 'eager'}
        fluid={fluid}
        alt={alt}
        {...restProps}
      />
    </div>
  ) : null;
};

GatsbyImage.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  isLazyLoading: true,
};

export default GatsbyImage;

export const query = graphql`
  fragment FragmentSmallImage on File {
    childImageSharp {
      fluid(maxWidth: 500, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentMiddleImage on File {
    childImageSharp {
      fluid(maxWidth: 950, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentLargeImage on File {
    childImageSharp {
      fluid(maxWidth: 1920, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentGatsbyProps on TGatsbyImageProperties {
    fallbackUrl
    url
    altText
  }

  fragment FragmentGatsbyImageSm on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentSmallImage
    }
  }

  fragment FragmentGatsbyImageMd on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentMiddleImage
    }
  }

  fragment FragmentGatsbyImageLg on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentLargeImage
    }
  }
`;
