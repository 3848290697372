import React, { useContext } from 'react';
// @ts-ignore
import { UmbracoControls } from '../../../../@types/umbraco/Controls.d.ts';
import useSiteSettings from './useSiteSettings';

const SiteSettingsContext = React.createContext<ReturnType<typeof useSiteSettings>>({
  siteSettings: {
    siteUrl: '',
    baseUrl: '1',
    siteName: '',
    lang: '',
    languages: [],
    dir: [],
    languageLetters: [],
    salsifyDataSource: UmbracoControls.ToggleStatuses.Off,
    salsifyLanguageLetters: 'GB',
    marketCode: 'SA',
    baseLang: '',
  },
  rootLang: 'en-gb',
});

export const SiteSettingsContextProvider = ({
  value,
  children,
}: {
  value: Parameters<typeof useSiteSettings>[0];
  children: React.ReactNode;
}) => {
  return (
    <SiteSettingsContext.Provider value={{ ...useSiteSettings(value) }}>
      {children}
    </SiteSettingsContext.Provider>
  );
};

export const useSiteSettingsContext = () => useContext(SiteSettingsContext);

export default SiteSettingsContext;
