import React, { FC } from 'react';
import classNames from 'classnames';

import Container from 'gatsby-theme-husky/src/layout/Container';
import TopFooter from 'gatsby-theme-husky/src/components/Footer/TopFooter';
import MiddleFooter from 'gatsby-theme-husky/src/components/Footer/MiddleFooter';
import BottomFooter from 'gatsby-theme-husky/src/components/Footer/BottomFooter';
import { IFooterProps } from 'gatsby-theme-husky/src/components/Footer/model';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import { Col, Row } from 'gatsby-theme-husky/src/layout';

import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';

import 'gatsby-theme-husky/src/components/Footer/Footer.scss';
import 'gatsby-theme-husky/src/components/Footer/FooterRTL.scss';
import './FooterExtends.scss';
import './FooterExtendsOverride.scss';

const Footer: FC<IFooterProps> = ({
  bottomLinks,
  copyrightBlock,
  navigation,
  mediaLinks,
  hideMediaLinks,
  dataLogo,
  disclaimer,
  disclaimerDesktopImage,
  disclaimerMobileImage,
  imageDisclaimer,
}) => {
  const { isMobile } = useScreenRecognition();
  const disclaimerProps = imageDisclaimer?.[0].properties;

  return (
    <>
      {disclaimer ? (
        <Container>
          <p className="disclaimer">{disclaimer}</p>
        </Container>
      ) : null}
      {(disclaimerDesktopImage || disclaimerProps?.disclaimerDesktopSvg) && !isMobile ? (
        <Container
          className={classNames({
            'disclaimer-fixed': disclaimerProps?.disclaimerFixed,
          })}
        >
          <Row>
            <Col sm="12">
              <div className="disclaimer__image-wrapper">
                {disclaimerProps?.disclaimerDesktopSvg ? (
                  <DangerouslySetInnerHtml
                    html={disclaimerProps?.disclaimerDesktopSvg?.svg?.content}
                    aria-label={disclaimerProps?.disclaimerLabel}
                  />
                ) : (
                  disclaimerDesktopImage?.map(({ properties: { image, imageAltLabel } }) => (
                    <GatsbyImage
                      key={imageAltLabel}
                      className="headerimagesrow__image"
                      fluid={image?.childImageSharp.fluid}
                      alt={imageAltLabel}
                    />
                  ))
                )}
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      {(disclaimerMobileImage || disclaimerProps?.disclaimerMobileSvg) && isMobile ? (
        <Container
          className={classNames({
            'disclaimer-fixed': disclaimerProps?.disclaimerFixed,
          })}
        >
          <Row>
            <Col sm="12">
              <div className="disclaimer__image-wrapper">
                {disclaimerProps?.disclaimerMobileSvg ? (
                  <DangerouslySetInnerHtml
                    html={disclaimerProps?.disclaimerMobileSvg.svg.content}
                    aria-label={disclaimerProps?.disclaimerLabel}
                  />
                ) : (
                  disclaimerMobileImage?.map(({ properties: { image, imageAltLabel } }) => (
                    <GatsbyImage
                      key={imageAltLabel}
                      className="headerimagesrow__image"
                      fluid={image?.childImageSharp.fluid}
                      alt={imageAltLabel}
                    />
                  ))
                )}
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      <footer
        className={classNames(`footer`, {
          'fixed-disclaimer--style': disclaimerProps?.disclaimerFixed,
        })}
      >
        <Container className="footer-container">
          <TopFooter
            dataLogo={dataLogo}
            dataMediaLinks={mediaLinks}
            dataFooterHideMediaLinks={hideMediaLinks}
          />
          <MiddleFooter dataNavigation={navigation} dataLinks={bottomLinks} />
          <BottomFooter dataCopyright={copyrightBlock} />
        </Container>
      </footer>
    </>
  );
};

export default Footer;
