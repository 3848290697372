function sliceText(str: string, limit = 80) {
  const temp = str.split(' ');
  let tempStr = temp[0];
  for (let i = 1; i < temp.length; i++) {
    if (tempStr.length + temp[i].length + 1 >= limit) {
      tempStr += '…';
      break;
    } else {
      tempStr += ` ${temp[i]}`;
    }
  }

  return tempStr;
}

export default sliceText;
