import React, { FC } from 'react';

import articleExtractor from './extractors/article';
import pageExtractor from './extractors/page';
import { PageSchemaProps, ExtractorsMapObject } from './models';

const extractorsMap: ExtractorsMapObject = {
  Article: articleExtractor,
  default: pageExtractor,
};

const PageSchema: FC<PageSchemaProps> = ({
  siteSettings,
  brandSettings,
  type,
  name,
  path,
  data,
}) => {
  const { siteUrl, siteName, lang } = siteSettings;
  const pageHref = path ? `${siteUrl}${path}` : siteUrl;

  const brandInformation = {
    ...brandSettings,
    lang,
    siteName,
    siteUrl,
    sameAs: [
      ...(brandSettings.youtubeUrl ? [brandSettings.youtubeUrl] : []),
      ...(brandSettings.facebookUrl ? [brandSettings.facebookUrl] : []),
    ],
  };

  const extractor = extractorsMap[type] || extractorsMap.default;
  const jsonLd = {
    name: name || siteName,
    ...{
      '@context': 'http://schema.org',
      '@type': type,
    },
    ...extractor(pageHref, data, brandInformation),
  };
  const structuredData = JSON.stringify(jsonLd);

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />;
};

export default PageSchema;
