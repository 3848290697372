export namespace UmbracoControls {
  export enum ToggleStatuses {
    On = '1',
    Off = '0',
  }
  type Toggle = ToggleStatuses.On | ToggleStatuses.Off;

  type TextBox = string;

  type CheckboxList = string[];

  type Dropdown = string[];

  type RichTextEditor = string;

  type RadioButtonList = string | null;

  type CustomizableImageCropper = {
    fallbackUrl: string;
    variants: [
      {
        alias: string;
        fallbackQuery: string;
        type: string;
        url: string;
      }
    ];
  };

  type CompositionStructurePicker<T> = T[];

  type NodePicker<T> = T[];

  type CustomStructurePicker<T = { [key: string]: string }> = {
    properties: T;
    structure: string;
  };

  type MultiUrlPicker = {
    name: string;
    url: string;
    icon?: string;
    queryString?: string;
  }[];

  type MultiUrlPickerItem = {
    name: string;
    url: string;
    icon?: string;
    queryString?: string;
  };

  type MediaPicker = {
    fallbackUrl: string;
    url: string;
  };

  type ImagePicker = {
    alText: string;
    fallbackUrl: string;
    url: string;
  };

  type ColorPicker = {
    label: string;
    value: string;
  };
}
