import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { IBottomFooterProps } from './model';

import './BottomFooter.scss';
import './BottomFooterExtends.scss';

const BottomFooter: FC<IBottomFooterProps> = ({ dataCopyright }) => (
  <DangerouslySetInnerHtml html={dataCopyright} className="bottom-footer-wrapper" />
);

export default BottomFooter;
