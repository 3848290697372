import { ArticleExtractor } from '../models';

const extractor = (
  pageHref: string,
  { name, createDate, updateDate, author }: ArticleExtractor,
  { brandName, siteUrl, sameAs }
): object => {
  const url = new URL(pageHref);

  return {
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': pageHref,
    },
    headline: name,
    datePublished: createDate,
    dateModified: updateDate,
    author: {
      '@type': 'Person',
      name: author || brandName,
    },
    publisher: {
      '@type': 'Organization',
      name: brandName,
      url: siteUrl,
      sameAs,
      logo: {
        '@type': 'ImageObject',
        url: `${url.origin}/logo.png`,
      },
    },
  };
};

export default extractor;
