import { SiteSettings } from '../models';

const ROOT_LANG = 'en-gb';

const useSiteSettings = (siteSettingsData: SiteSettings) => {
  return {
    siteSettings: siteSettingsData,
    rootLang: ROOT_LANG,
  };
};

export default useSiteSettings;
