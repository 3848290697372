const TABLET_BREAKPOINT = 768;
const LARGE_TABLET_BREAKPOINT = 992;
const DESKTOP_BREAKPOINT = 1024;
const LARGE_DESKTOP_BREAKPOINT = 1200;
const EXTRA_LARGE_DESKTOP_BREAKPOINT = 1440;

export {
  TABLET_BREAKPOINT,
  LARGE_TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
  EXTRA_LARGE_DESKTOP_BREAKPOINT,
};
