function titleCapitalized(str: string): string {
  if (
    str.toLowerCase().includes('point') ||
    str.toLowerCase().includes('spot') ||
    str.toLowerCase().includes('bod g')
  ) {
    return str;
  }

  let newStr: string;
  newStr = str?.trim();
  const indexOfdot = str.indexOf('.');
  const indexOfquote = str.indexOf('„');

  if (str[indexOfdot] === '.') {
    newStr =
      str?.substring(0, indexOfdot + 2) +
      str?.[indexOfdot + 2]?.toUpperCase() +
      str?.substring(indexOfdot + 3).toLocaleLowerCase();
  } else {
    newStr = str?.[0]?.toUpperCase() + str?.substring(1)?.toLocaleLowerCase();
  }

  if (str[indexOfquote] === '„') {
    newStr =
      str?.substring(0, indexOfquote + 1) +
      str?.[indexOfquote + 1]?.toUpperCase() +
      str?.substring(indexOfquote + 2).toLocaleLowerCase();
  }

  if (str[0] === '¿' || str[0] === '¡') {
    newStr = str[0] + str?.[1]?.toUpperCase() + str?.substring(2).toLocaleLowerCase();
  }

  return newStr;
}

export default titleCapitalized;
