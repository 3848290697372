import React, { FC, useRef, useMemo } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { useAllSitePageContext } from 'gatsby-theme-husky/src/context/allSitePage';
import useClickOutside from 'gatsby-theme-husky/src/hooks/useClickOutside';
import { isBrowser } from 'gatsby-theme-husky/src/utils/browser';
import { HeaderLanguageSelectorProps } from './model';
import 'gatsby-theme-husky/src/components/Header/HeaderLanguageSelector/HeaderLanguageSelector.scss';

const HeaderLanguageSelector: FC<HeaderLanguageSelectorProps> = ({
  languages,
  isActive,
  onClick,
  languageLetters,
  baseLang,
}) => {
  const { getPageUrlByLang } = useAllSitePageContext();

  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    if (isActive) {
      onClick();
    }
  });
  const links = useMemo(() => {
    return languages
      .reduce((acc: { url: string; langName: string }[], lang) => {
        if (isBrowser()) {
          const pageUrl = document.location.pathname;
          const langPrefix = lang === '/' ? baseLang && baseLang : lang;
          const langName = langPrefix.replace('/', '');
          const url = getPageUrlByLang(pageUrl, langName);

          return [
            ...acc,
            {
              url,
              langName: langName.toUpperCase(),
            },
          ];
        }

        return acc;
      }, [])
      .filter(({ langName }) => {
        return langName !== languageLetters[0];
      });
  }, [getPageUrlByLang, languageLetters]);

  return (
    <button
      onClick={onClick}
      className={classnames('language-selector', { 'active-drop': isActive })}
      aria-label={languageLetters[0]}
      ref={wrapperRef}
      type="button"
    >
      <span className="language-selector__opener">{languageLetters[0]}</span>
      {links ? (
        <div className="language-selector__slide">
          <ul className="language-selector__list">
            {links.map(({ url, langName }) => (
              <li key={url}>
                <Link className="language-selector__link" to={url}>
                  {langName}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </button>
  );
};

export default HeaderLanguageSelector;
