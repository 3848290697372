import React, { FC } from 'react';
import { Link } from 'gatsby';

import Row from 'layout/Row';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { useAllSitePageContext } from 'context/allSitePage';
import { useSiteSettingsContext } from 'context/siteSettings';

import { ITopFooterProps } from './model';
import './TopFooter.scss';
import './TopFooterExtends.scss';

const TopFooter: FC<ITopFooterProps> = ({ dataLogo, dataMediaLinks, dataFooterHideMediaLinks }) => {
  const { getPageUrlByLang } = useAllSitePageContext();
  const { siteSettings } = useSiteSettingsContext();

  const langDependedHomeUrl = getPageUrlByLang('/', siteSettings.lang);
  const isMediaLinksVisible = !Number(dataFooterHideMediaLinks);

  return (
    <>
      <Link
        className="top-footer-logo__link"
        to={langDependedHomeUrl}
        aria-label={dataLogo?.brandLogoLinkAriaLabel}
      >
        <DangerouslySetInnerHtml
          html={dataLogo?.brandLogo.svg.content}
          alt={dataLogo?.brandLogoAlt}
        />
      </Link>
      {isMediaLinksVisible ? (
        <Row className="top-footer-row ">
          {dataMediaLinks?.map(({ properties: mediaLinkProperties }) => {
            return (
              <a
                href={mediaLinkProperties.link[0].url}
                target="__blank"
                key={mediaLinkProperties.link[0].name}
                aria-label={mediaLinkProperties.mediaAriaLabel}
              >
                <DangerouslySetInnerHtml
                  className="top-footer-row__svg"
                  html={mediaLinkProperties.image.svg.content}
                  alt={mediaLinkProperties.altLabel}
                />
              </a>
            );
          })}
        </Row>
      ) : null}
    </>
  );
};

export default TopFooter;
