import { CommonSettings } from '../models';

function getSettingsPropertiesByStructure(
  settings: CommonSettings,
  structure: string
): CommonSettings['blocks'][0]['properties'] | undefined {
  if (!settings?.blocks || !structure) return undefined;

  return settings.blocks.find((item) => item.structure === structure)?.properties;
}

export default getSettingsPropertiesByStructure;
