import React, { FC } from 'react';
import { Link } from 'gatsby';

import Row from 'layout/Row';
import Col from 'layout/Col';

import './MiddleFooter.scss';
import './MiddleFooterExtends.scss';
import { IMiddleFooterProps } from './model';

const MiddleFooter: FC<IMiddleFooterProps> = ({ dataNavigation, dataLinks }) => (
  <>
    <Row className="middle-footer-wrapper-top">
      {dataNavigation?.map((navigationLink, index) => {
        return (
          <Col className="wrapper-col-top" key={`${navigationLink.properties.url[0].name}${index}`}>
            <Link to={navigationLink.properties.url[0].url}>
              {navigationLink.properties.url[0].name}
            </Link>
          </Col>
        );
      })}
    </Row>
    <Row className="middle-footer-wrapper-bottom">
      {dataLinks?.map((siteLink, index) => {
        return (
          <Col className="wrapper-col-bottom" key={`${siteLink.properties.url[0].name}${index}`}>
            <Link to={siteLink.properties.url[0].url}>{siteLink.properties.url[0].name}</Link>
          </Col>
        );
      })}
    </Row>
  </>
);

export default MiddleFooter;
