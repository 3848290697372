import { CustomStructurePicker } from '../models';

function getStructurePropertiesData(
  structures: CustomStructurePicker[],
  structureName: string,
  fieldName: string
): string | undefined {
  return structures.filter(({ structure }) => structure === structureName)[0]?.properties[
    fieldName
  ];
}

export default getStructurePropertiesData;
