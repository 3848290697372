const indexLastNum = (str: string) => {
  let newStr: string;
  if (!Number.isNaN(+str.substr(-1))) {
    newStr = str.substr(0, str.length - 1) + str.substr(-1).sup();

    return newStr;
  }

  return str;
};
export default indexLastNum;
