import capitalizeEsp from './capitalizeEsp';
import capitalize from './capitalize';
import titleCapitalized from './titleCapitalized';
import eraseNum from './eraseNum';
import removeHtmlTags from './removeHtmlTags';
import sliceText from './sliceText';
import transformUrlToName from './transformUrlToName';
import indexLastNum from './indexLastNum/indexLastNum';

export default {
  capitalizeEsp,
  indexLastNum,
  capitalize,
  eraseNum,
  titleCapitalized,
  removeHtmlTags,
  sliceText,
  transformUrlToName,
};
