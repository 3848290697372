import { stringUtils } from 'utils';
import getStructurePropertiesData from 'utils/customStructurePicker/getStructurePropertiesData';

import { CustomStructurePicker } from '../models';

function getStructurePropertiesDataWOHtml(
  structures: CustomStructurePicker[],
  structureName: string,
  fieldName: string,
  limit = 150
): string {
  return stringUtils.sliceText(
    stringUtils.removeHtmlTags(
      getStructurePropertiesData(structures, structureName, fieldName) || ''
    ),
    limit
  );
}

export default getStructurePropertiesDataWOHtml;
