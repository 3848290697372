import { useMemo } from 'react';

import { stringUtils } from 'utils';
import { useSiteSettingsContext } from 'context/siteSettings';

import { AllSitePage, AllSitePageNodeContext } from '../models';

const useAllSitePage = (allSitePage: AllSitePage) => {
  const { rootLang } = useSiteSettingsContext();

  return useMemo(() => {
    const umbracoIdToPageContextsMap = new Map<
      AllSitePageNodeContext['umbracoId'],
      AllSitePageNodeContext[]
    >();
    const urlToPageContextMap = new Map<AllSitePageNodeContext['url'], AllSitePageNodeContext>();

    allSitePage.nodes.forEach(({ context }) => {
      if (context) {
        umbracoIdToPageContextsMap.set(context.umbracoId, [
          ...(umbracoIdToPageContextsMap.get(context.umbracoId) || []),
          context,
        ]);
        urlToPageContextMap.set(context.url, context);
      }
    });

    function getPageContextByUrl(url: string) {
      return urlToPageContextMap.get(url) || urlToPageContextMap.get(`${url}/`);
    }

    function getPageNameByUrl(url: string): string {
      const pageName = getPageContextByUrl(url)?.name;
      const fallbackValue = stringUtils.transformUrlToName(url);

      return stringUtils.capitalize(pageName || fallbackValue);
    }

    function getPageUrlByLang(url: string, lang: string = rootLang): string {
      const pageContext = getPageContextByUrl(url);

      if (pageContext) {
        const pageUmbracoId = pageContext.umbracoId;
        const pagesWithSameUmbracoId = umbracoIdToPageContextsMap.get(pageUmbracoId) || [];
        const samePageWithLang = pagesWithSameUmbracoId.find(({ lang: pageLang }) =>
          pageLang.includes(lang)
        );

        if (samePageWithLang) return samePageWithLang.url;
      }

      if (url === '/') {
        return '/';
      }

      return getPageUrlByLang('/', lang);
    }

    return {
      allSitePages: allSitePage.nodes,
      getPageContextByUrl,
      getPageNameByUrl,
      getPageUrlByLang,
    };
  }, [allSitePage]);
};

export default useAllSitePage;
