function capitalizeEsp(str: string): string {
  const excepitonsArray = ['San ', 'Durex', 'punto', 'VIH', 'spot', '.'];
  if (excepitonsArray.some((exc) => String(str).includes(exc))) {
    return str;
  }

  if (
    (String(str).charAt(0) === '¿' || String(str).charAt(0) === '¡') &&
    String(str).trim().substr(-3).toLowerCase() === 'its'
  ) {
    // Case if string starts with "¿" or "¡" and ends with "its"
    return (
      String(str).charAt(0) +
      String(str).charAt(1).toUpperCase() +
      String(str).slice(2, -3) +
      String(str).slice(-3).toUpperCase()
    );
  }
  // Case if string starts with "¿" or "¡"
  if (String(str).charAt(0) === '¿' || String(str).charAt(0) === '¡') {
    return (
      String(str).charAt(0) +
      String(str).charAt(1).toUpperCase() +
      String(str).slice(2).toLowerCase()
    );
  }
  // Case if string ends with "its"
  if (String(str).trim().substr(-3).toLowerCase() === 'its') {
    return (
      String(str).charAt(0).toUpperCase() +
      String(str).slice(1, -3) +
      String(str).slice(-3).toUpperCase()
    );
  }

  return String(str).charAt(0).toUpperCase() + String(str).slice(1).toLowerCase();
}

export default capitalizeEsp;
