import React, { useContext } from 'react';

import useAllSitePage from './useAllSitePage';

const AllSitePageContext = React.createContext<ReturnType<typeof useAllSitePage>>({
  allSitePages: [],
  getPageContextByUrl: () => undefined,
  getPageNameByUrl: () => '',
  getPageUrlByLang: () => '/',
});

export const AllSitePageContextProvider = ({
  value,
  children,
}: {
  value: Parameters<typeof useAllSitePage>[0];
  children: React.ReactNode;
}) => {
  return (
    <AllSitePageContext.Provider value={{ ...useAllSitePage(value) }}>
      {children}
    </AllSitePageContext.Provider>
  );
};

export const useAllSitePageContext = () => useContext(AllSitePageContext);

export default AllSitePageContext;
