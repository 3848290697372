import React, { FC, useCallback, useState } from 'react';
import { navigate } from 'gatsby';
import classnames from 'classnames';

import Search from 'components/Header/Search/Search';
import HeaderLanguageSelector from 'components/Header/HeaderLanguageSelector/HeaderLanguageSelector';
import Button from 'common/Button';

import HeaderLogo from './HeaderLogo';
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import MobileHeaderNavigation from './MobileHeaderNavigation/MobileHeaderNavigation';
import { HeaderProps } from './models';
import './Header.scss';
import './HeaderExtends.scss';
import './HeaderRTL.scss';

const Header: FC<HeaderProps> = ({
  navigationTexts,
  logo,
  allNodeNavigation,
  languages,
  languageLetters,
  baseLang,
  handleActiveListPage,
  searchTexts: { searchAriaLabel, searchPlaceholder, closeSearchAreaLabel },
}) => {
  const [mobileStateVisibility, setMobileStateVisibility] = useState<boolean>(false);
  const [mobileSearchActive, setMobileSearchActive] = useState<boolean>(false);
  const [isLanguageSelectorActive, setIsLanguageSelectorActive] = useState<boolean>(false);

  const showMobileMenu = useCallback(() => {
    setMobileStateVisibility(!mobileStateVisibility);
  }, [mobileStateVisibility, setMobileStateVisibility]);

  const showMobileSearch = useCallback(() => {
    setMobileSearchActive(!mobileSearchActive);
    setIsLanguageSelectorActive(false);
  }, [mobileSearchActive, setIsLanguageSelectorActive, isLanguageSelectorActive]);

  const handleSearchSubmit = useCallback((searchString: string) => {
    if (searchString.length) {
      const lang = languageLetters[0].toLocaleLowerCase();
      const langPrefix =
        lang === baseLang.replace('/', '') || languages.length === 1 ? '' : `/${lang}`;
      navigate(`${langPrefix}/search?q=${searchString}`);
    }
  }, []);

  const handleSearchOutsideClick = useCallback(() => {
    setMobileSearchActive(false);
    setIsLanguageSelectorActive(false);
  }, [setMobileSearchActive, setIsLanguageSelectorActive]);

  const handleLanguageSelectorToggle = useCallback(() => {
    setIsLanguageSelectorActive(!isLanguageSelectorActive);
    setMobileSearchActive(false);
  }, [setIsLanguageSelectorActive, isLanguageSelectorActive]);

  const headerWrapperClasses = classnames({
    'header-wrapper header-wrapper-mobile': mobileStateVisibility,
    'header-wrapper': !mobileStateVisibility,
    'header-wrapper__disabled-scroll': mobileSearchActive,
  });
  const headerMenuIcon = classnames({
    'main-nav-toggle-crose': mobileStateVisibility,
    'main-nav-toggle': !mobileStateVisibility || mobileSearchActive,
  });

  return (
    <header className={headerWrapperClasses}>
      <div className="header">
        <div className="header-bottom">
          <div className="wrapper">
            <HeaderLogo logo={logo} />

            {!mobileStateVisibility ? (
              <HeaderNavigation {...{ handleActiveListPage, allNodeNavigation }} />
            ) : null}
            <Button
              className={headerMenuIcon}
              handler={mobileSearchActive ? showMobileSearch : showMobileMenu}
              type="button"
              ariaLabel={
                !mobileStateVisibility
                  ? navigationTexts.openMobileNavigationAriaLabel
                  : navigationTexts.closeMobileMenuAriaLabel
              }
            />
          </div>
        </div>
        <div className="header-top">
          <Search
            {...{ showMobileSearch, searchAriaLabel, mobileSearchActive, closeSearchAreaLabel }}
            searchInputPlaceHolder={searchPlaceholder}
            onOutsideClick={handleSearchOutsideClick}
            onSubmit={handleSearchSubmit}
          />
          {languages.length > 1 ? (
            <HeaderLanguageSelector
              {...{ languageLetters, languages, handleSearchOutsideClick, baseLang }}
              isActive={isLanguageSelectorActive}
              onClick={handleLanguageSelectorToggle}
            />
          ) : null}
        </div>
      </div>
      {mobileStateVisibility ? (
        <MobileHeaderNavigation
          allNodeNavigation={allNodeNavigation}
          navigationTexts={navigationTexts}
          toggleMobileMenu={showMobileMenu}
        />
      ) : null}
    </header>
  );
};
export default Header;
