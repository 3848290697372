import { useState, useEffect } from 'react';
import {
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  LARGE_TABLET_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
  EXTRA_LARGE_DESKTOP_BREAKPOINT,
} from './constants';
import { UseScreenRecognitionHook } from './model';

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const [isMobile, setIsMobile] = useState<null | boolean>(null);
  const [isTablet, setIsTablet] = useState<null | boolean>(null);
  const [isMiddleTablet, setIsMiddleTablet] = useState<null | boolean>(null);
  const [isDesktop, setIsDesktop] = useState<null | boolean>(null);
  const [isLargeDesktop, setIsLargeDesktop] = useState<null | boolean>(null);
  const [isExtraLargeDesktop, setIsExtraLargeDesktop] = useState<null | boolean>(null);

  const handleResize = () => {
    const width = window && window.innerWidth;

    setIsMobile(width < TABLET_BREAKPOINT);
    setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT);
    setIsMiddleTablet(width >= TABLET_BREAKPOINT && width < LARGE_TABLET_BREAKPOINT);
    setIsDesktop(width >= DESKTOP_BREAKPOINT);
    setIsLargeDesktop(width >= LARGE_DESKTOP_BREAKPOINT);
    setIsExtraLargeDesktop(width >= EXTRA_LARGE_DESKTOP_BREAKPOINT);
  };

  useEffect(() => {
    handleResize();
    window && window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isMiddleTablet,
    isLargeDesktop,
    isExtraLargeDesktop,
  };
};

export default useScreenRecognition;
